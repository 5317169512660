import React from 'react';
import Image from '@/components/elements/Image';
import ThreeColumn from '@/components/sections/ThreeColumn';
import Link from '@/components/elements/Link';
import type { QuoteModuleTypes } from './QuoteModule.types';
import * as styles from './QuoteModule.module.scss';

export default function QuoteModule({
  authorImage,
  authorName,
  authorRole,
  link,
  linkLabel,
  grid,
  image,
  quote,
  title,
}: QuoteModuleTypes) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.innerWrapper}>
          <div className={`p-0 col-md-6 ${styles.textContent}`}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.mark}>“</p>
            <blockquote className={styles.quote}>“{quote}“</blockquote>
            <div className={styles.authorWrapper}>
              {authorImage && (
                <Image data={authorImage} fixed options={{ width: 45, height: 45 }} className={styles.authorImage} />
              )}
              <div className={styles.authorContent}>
                <h3 className={styles.authorTitle}>{authorName}</h3>
                <p>{authorRole}</p>
              </div>
            </div>
            {link && (
              <Link href={link?.cached_url || link?.url} className={styles.quoteLink} display_arrow>
                {linkLabel}
              </Link>
            )}
          </div>
          {image && (
            <div className={`p-0 col-md-6 ${styles.imageWrapper}`}>
              <Image data={image} fluid />
            </div>
          )}
        </div>
        {grid && <ThreeColumn grid={grid} />}
      </div>
    </div>
  );
}
