// extracted by mini-css-extract-plugin
export var authorContent = "QuoteModule-module--authorContent--77f5b";
export var authorImage = "QuoteModule-module--authorImage--33191";
export var authorTitle = "QuoteModule-module--authorTitle--259ff";
export var authorWrapper = "QuoteModule-module--authorWrapper--65582";
export var content = "QuoteModule-module--content--6063f";
export var imageWrapper = "QuoteModule-module--imageWrapper--8bb0f";
export var innerWrapper = "QuoteModule-module--innerWrapper--e5f8c";
export var link = "QuoteModule-module--link--bd4cb";
export var mark = "QuoteModule-module--mark--8492d";
export var opacityEnter = "QuoteModule-module--opacityEnter--b9ab5";
export var opacityExit = "QuoteModule-module--opacityExit--45ce7";
export var quote = "QuoteModule-module--quote--b93df";
export var quoteLink = "QuoteModule-module--quoteLink--9ffc6";
export var rollDown = "QuoteModule-module--rollDown--732d8";
export var rollUp = "QuoteModule-module--rollUp--ca65c";
export var slideInDown = "QuoteModule-module--slideInDown--d21df";
export var slideOutUp = "QuoteModule-module--slideOutUp--d3c29";
export var splashEnter = "QuoteModule-module--splashEnter--0b6d2";
export var splashExit = "QuoteModule-module--splashExit--20a51";
export var textContent = "QuoteModule-module--textContent--79bb8";
export var title = "QuoteModule-module--title--f165a";
export var wrapper = "QuoteModule-module--wrapper--66826";