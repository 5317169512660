import React from 'react';
import Image from '@/components/elements/Image';
import { Button } from '@/components/elements/Button';
import type { BlogPostTypes } from '@/components/templates/BlogPost/BlogPost.types';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import generatePagePath from '~/config/generatePagePath';
import SectionRichText from '../SectionRichText';
import type { ThreeColumnTypes } from './ThreeColumn.types';
import * as styles from './ThreeColumn.module.scss';

export default function ThreeColumn({
  title,
  grid,
  post,
  postButton,
  solidVariant,
  biggerHeadings,
  mobileFullWidth = false,
  gradient = 'dark',
  top_spacing = 'md',
  bottom_spacing = 'md',
}: ThreeColumnTypes) {
  function renderPostReference({ name, content, full_slug }: StoryblokEntry<BlogPostTypes>) {
    const { image } = content || {};
    return (
      <div className={styles.postWrapper}>
        {image && (
          <div className={styles.postImageWrapper}>
            <Image data={image} fluid className={styles.postImage} />
          </div>
        )}
        <div className={styles.postTextWrapper}>
          <p className={styles.postEyebrow}>blog</p>
          <h3 className={styles.postTitle}>{name}</h3>
          <Button link={generatePagePath(full_slug)} variant="primary">
            {postButton}
          </Button>
        </div>
      </div>
    );
  }

  if (!grid || !grid.length) return null;
  return (
    <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing}>
      <div
        className={styles.wrapper}
        data-solid-variant={String(solidVariant)}
        data-gradient={String(gradient)}
        data-mobile-full-width={String(mobileFullWidth)}
        data-display-post-container={String(!!post?.full_slug)}
        data-includes-title={String(!!title)}
      >
        {title && <h2 className={`col-md-10 ${styles.title}`}>{title}</h2>}

        <ul className={styles.list}>
          {grid.map(({ _uid, body, icon }) => (
            <li key={_uid} className={styles.listItem} data-bigger-heading={String(biggerHeadings)}>
              {icon && (
                <div className={styles.iconWrapper}>
                  <Image data={icon} fixed options={{ width: 100 }} />
                </div>
              )}
              <SectionRichText body={body} className={styles} />
            </li>
          ))}
        </ul>
      </div>
      {post?.full_slug && renderPostReference({ ...post })}
    </ModuleWrapper>
  );
}
